// formatted with prettier (shift + alt + f)
import React from 'react';
import update from 'immutability-helper';
import BtButton from '../bt-button/bt-button';
import 'react-datepicker/dist/react-datepicker.css';
import './app-project-single-purchase.css';
import styles from './app-project-single-purchase.module.scss';
import _ from 'lodash';
import $ from 'jquery';
import { ServiceProjects } from '../../services/service-projects';
import { ServiceContract } from "../../services/service-contract";
import moment from 'moment';
import 'moment/locale/sv.js';
import { ServiceExcel } from '../../services/service-excelController';
import { NotificationManager } from 'react-notifications';
import { ServiceDownload } from '../../services/service-download';
import { formatAmount } from '../../util';
import CreditSafeReport from '../app-credit-safe/app-credit-safe-report.jsx';
import BlockIcon from '@material-ui/icons/Block';
import OptionsMenu from '../../components/shared/OptionsMenu/OptionsMenu';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import { MoreTimeIcon } from '../../components/svgs/moreTimeIcon';
import { withRouter } from 'react-router-dom';
import appState from '../../state/AppStateContainer';
import RequestQuoteIcon from '../../components/svgs/requestQuoteIcon';
import Toolbar from '../../components/shared/Toolbar/Toolbar';
import GroupIcon from '../../components/svgs/groupIcon';
import PhoneIcon from '../../components/svgs/phoneIcon';
import DotsIcon from '../../components/svgs/dotsIcon';
import MailIcon from '../../components/svgs/emailIcon';
import TrashIcon from '../../components/svgs/trashIcon';
import MessageLog from '../../components/shared/MessageLog/MessageLog';
import Selector from '../../components/shared/Selector/Selector';
import { components } from 'react-select';
import Modal from '../../components/shared/Modal/Modal';
import TitleWrapper from '../../components/shared/TitleWrapper/TitleWrapper';
import { ServiceUser } from '../../services/service-user';
import ArrowIcon from '../../components/svgs/arrowIcon';
import AddIcon from '../../components/svgs/addIcon';
import UploadIcon from '../../components/svgs/uploadIcon';
import WinnerIcon from '../../components/svgs/winnerIcon';
import { CustomAvatar } from '../../components/shared';
import { Loading } from '../../components/shared';
import ExcelIcon from '../../assets/images/excel.png'
import { bidIntentOptions, BidIntentStatus } from '../page-quotation/BidIntentControls/BidIntentControls';
import { ReactComponent as TriangleWarningIcon } from '../../assets/svg/triangleWarningIcon.svg';
import { ReactComponent as MessageIcon } from '../../assets/svg/messageIcon.svg';
import { ReactComponent as OpenedEyeIcon } from '../../assets/svg/openedEyeIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/svg/circlePlusIcon.svg';
import { ReactComponent as CircleQuestionIcon } from '../../assets/svg/circleQuestionIcon.svg';
import { ReactComponent as CircleDollarIcon } from '../../assets/svg/circleDollarIcon.svg';
import { ReactComponent as CircleXIcon } from '../../assets/svg/circleXIcon.svg';
import { ReactComponent as CircleCheckIcon } from '../../assets/svg/circleCheckIcon.svg';
import HTMLParser from '../../components/shared/HTMLParser/HTMLParser';
import SupplierBlockedReasons from '../app-supplier-database/supplier-blocked-reasons';
import DateExtensionModal from '../app-project-overview-item/DateExtensionModal';
import File from '../../components/File/File';
import FileUpload from '../../components/shared/FileUpload/FileUpload';
import { FileScope, PrequalificationDecision, SavingState } from '../../util/appEnum';
import { getImageUrl } from '../../components/shared/ImagePreview/ImagePreview';
import Field from '../../components/shared/Field/Field';
import DatePicker from '../../components/shared/DatePicker/DatePicker';
import ContractModal from '../app-project-overview-item/ContractModal';
import { SearchIcon2 } from '../../components/svgs/searchIcon2';
import Card from '../../components/shared/Card/card';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import BidOverview from './bid-overview';
import * as Sentry from '@sentry/react';
import NotificationCounter from '../../components/shared/NotificationCounter/NotificationCounter';
import TeamBuilder from '../../components/shared/TeamBuilder/TeamBuilder';
import ContractIcon from '../../components/svgs/contractIcon';
import TableField from '../app-project-purchase-list/TableField/TableField';
import TableDatePicker from '../app-project-purchase-list/TableDatePicker/TableDatePicker';


const MAX_TEAM_SIZE = 5;

function shortFilename(file, strLen = 20, separator = "...") {
  if (file.length < strLen) {
    return file;
  }

  var ext = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
  var fileName = file
    .split(".")
    .slice(0, -1)
    .join(".");
  file = fileName;
  strLen -= ext.length + 1;

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    file.substr(0, frontChars) +
    separator +
    file.substr(file.length - backChars) +
    "." +
    ext
  );
}

const formatDate = (date) => date ? moment(date).locale('sv').format('D MMMM YYYY HH:mm') : '';
class AppProjectSinglePurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // suppliers: this.sortSuppliers(props.suppliers),
      progress: props.progress || 100,
      label: props.label || null,
      priceTypes: this.getPriceTypes(props.suppliers),
      budgetVal: props.budget || '',
      selectedItem: null,
      versionData: [],
      suppliers: this.sortSuppliers(props.suppliers),
      selectedFile: 1,
      attachments: this.props.attachments,
      showRiskIndicator: false,
      showRiskLoader: false,
      fetchedAnalysis: false,
      lockedButtonDisable: false,
      showPhoneLogModal: false,
      phoneLogValue: null,

      areaUsers: [],
      showWinnerModal: false,
      winnerValue: null,
      showOnlyWinningSupplier: true,
      showSupplierThatDeclined: false,
      showDateExtensionModal: false,
      sendAgain: {},
      agreementModalKey: 0,
      showAllVersions: false,
      temporaryAreaLeadTime: {},
      showMassMessagingModal: false,
      massMessagingEditorValue: '',
      purchases: [],
      massMessagingFilters: [],
      massMessagingRecipientSelection: {},
      shownRecipients: [],
      searchString: '',
    };
  }

  // We use it to scroll at the bottom of the phone log div
  phoneLogDiv = null;

  getTheLatestVersionForSuppliers = ({ versionData, suppliers }) => {
    const getVersion = versionData && !_.isEmpty(versionData) ? versionData[0].versions : undefined;

    if (suppliers && suppliers.length && getVersion) {
      const latestVersions = {};
      suppliers.forEach(supplier => {
        const { supplierId } = supplier;
        const version = `version${supplierId._id}`;
        const quotationVersions = [];

        for (const qv of getVersion) {
          for (const s of qv.suppliers) {
            if (s.supplierId === supplierId._id) quotationVersions.push(qv);
          }
        }

        if (!_.isEmpty(quotationVersions)) {
          const latestVersion = quotationVersions[quotationVersions.length - 1].refVersion;
          latestVersions[version] = latestVersion;
        }
      });
      return latestVersions;
    }
  }

  checkContractSigning = () => {
    const { step } = this.props;

    if(step == 3 || step == 4){
      const area = this.props.currentProject.area.find(a => a.areaId._id == this.props.areaId);
      const signedContractExists = area?.contracts?.find(contract => contract?.lastStatus === "closed");
      if(step == 3 && (signedContractExists || area?.manualContract?.length)) this.changeStep(4); 
      else if(step == 4 && !signedContractExists && !area?.manualContract?.length) this.changeStep(3); 
    }
  }

  async componentDidMount() {
    const { areaId } = this.props;
    if (areaId) {
      this.checkContractSigning();
      
      let currentProject = this.props.currentProject;
      let projectSlug = currentProject.slug;
      let areaId = this.props.areaId;
      const versionData = await ServiceProjects.getVersionData(projectSlug, areaId);

      ServiceUser.getProjectUsersAreas(currentProject._id).then(res => {
        const areaU = res
          .filter(user => user.areaIds.indexOf(areaId) != -1)
          .map(user => {
            const userDetails = this.props.users.find(e => e.value == user.userId);
            if (userDetails) {
              return { name: userDetails.label, _id: userDetails.value, avatarUrl: user.avatarUrl };
            }
            return null;
          }).filter(user => user);
        this.setState({ areaUsers: areaU });
      });

      this.setState(
        {
          versionData: versionData,
          attachments: this.props.attachments,
        },
        () => {
          this.setState(
            {
              suppliers: this.sortSuppliers(this.props.suppliers),
              priceTypes: this.getPriceTypes(this.props.suppliers, {
                versionPrice: true,
              }),
            },
            () => this.setState(this.getTheLatestVersionForSuppliers({ versionData, suppliers: this.state.suppliers }))
          );
        },
      );
    }
    this.initiateDropdown();
  }

  getNewlyUpdatedLatestVersionsPerSupplier = (oldState, newState) => {
    const toChange = {};
    if (!oldState) {
      return { ...newState }; 
    }
    if (newState) {
      for (const [key, value] of Object.entries(newState)) {
        if (value != oldState[key]) {
          toChange[key] = value;
        }
      }
    }
    return toChange;
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(this.props.currentProject, nextProps.currentProject) && (
        this.props.dateEnd != nextProps.dateEnd ||
        _.isEqual(this.props.suppliers, nextProps.suppliers) ||
        nextProps.suppliers.length > 0
      )
    ) {
      let currentProject = this.props.currentProject;
      let projectSlug = currentProject.slug;
      let areaId = this.props.areaId;
      let versionData = this.state.versionData;
      const oldLatestVersionPerSupplier = this.getTheLatestVersionForSuppliers({ versionData, suppliers: this.state.suppliers });
      versionData = await ServiceProjects.getVersionData(projectSlug, areaId);
      const nextSuppliers = this.sortSuppliers(nextProps.suppliers);
      const newLatestVersionPerSupplier = this.getTheLatestVersionForSuppliers({ versionData, suppliers: nextSuppliers });
      const updatedLatestVersionsPerSupplier = this.getNewlyUpdatedLatestVersionsPerSupplier(
        oldLatestVersionPerSupplier, 
        newLatestVersionPerSupplier
      );
      this.setState(
        {
          versionData: versionData,
          attachments: _.isEmpty(this.state.attachments)
            ? this.props.attachments
            : this.state.attachments,
          suppliers: nextSuppliers,
          progress: nextProps.progress,
          label: nextProps.label,
          priceTypes: this.getPriceTypes(nextProps.suppliers, {
            versionPrice: true,
          }),
          ...updatedLatestVersionsPerSupplier
        }
      );
    }
    this.initiateDropdown();
  }

  dispactchChangeCallIcon(supplier, conversation) {
    if (this.props.changeCallIcon) {
      const result = this.props.changeCallIcon(supplier, conversation);
      if (result instanceof Promise) {
        result.then(val => {
          this.setState({
            suppliers: this.sortSuppliers(this.state.suppliers),
          });
        });
      }
    }
  }

  dispatchDateChange(dateEnd) {
    if (this.props.dateChangeEvent) {
      this.props.dateChangeEvent(dateEnd);
    }
  }

  dispatchWinnerChange(supplier, isRemoved) {
    if (this.props.winnerChangeEvent) {
      this.props.winnerChangeEvent(supplier, isRemoved);
    }
  }

  columnPriceClick(e) {
    var price = e.target.querySelector('.price');
    if (price) {
      price.focus();
    }
  }

  dateEndClickCallback(newValue) {
    if (newValue) {
      newValue = new Date(newValue.setUTCHours(0, 0, 0, 0));
    }

    this.dispatchDateChange(newValue);
  }

  addWinnerClickCallback(extraData, e) {
    this.updateWinnerState(extraData._id, true);
    this.dispatchWinnerChange(extraData, false);
  }

  removeWinnerClickCallback() {
    const suppliers = this.state.suppliers;
    const suppliersToBeRemoved = suppliers.filter(s => s.reward);

    suppliersToBeRemoved.forEach(supplierToBeRemoved => {
      if (supplierToBeRemoved) {
        this.updateWinnerState(supplierToBeRemoved.supplierId._id, false);
        this.dispatchWinnerChange(supplierToBeRemoved.supplierId, true);
      }
    })
  }

  onPriceTypeChange(event) {
    this.setSelectedPriceType(event.target.value, false);
  }

  updateWinnerState(supplierId, isRewarded) {
    let suppliers = this.state.suppliers;
    let newSupplierIndex = suppliers.findIndex(s => s.supplierId._id == supplierId);
    let newSuppliers = update(suppliers, {
      [newSupplierIndex]: { reward: { $set: isRewarded } },
    });
    this.setState({ suppliers: newSuppliers });
  }

  getQuoteValidity(validity) {
    return validity ? moment(validity).format('YYYY-MM-DD') : ""
  }

  parseDateEnd(dateEnd) {
    if (dateEnd == '' || dateEnd == '-' || dateEnd == undefined) {
      return '-';
    }

    return Date.parse(dateEnd);
  }

  getDaysLeft(dateEnd) {
    var endDate = this.parseDateEnd(dateEnd);
    if (!endDate || endDate == '-') {
      return '-';
    }

    var today = new Date();
    var month = today.getMonth() + 1;
    month = month < 10 ? '0' + month : month;

    var day = today.getDate();
    day = day < 10 ? '0' + day : day;

    var date = today.getFullYear() + '-' + month + '-' + day;
    var startDate = Date.parse(date);
    var timeDiff = endDate - startDate;
    var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
  }

  getStatus(daysLeft, supplierWinner) {
    var status = 'status-idle';
    if (supplierWinner) {
      status = 'status-success';
    } else if (daysLeft >= 12) {
      status = 'status-ongoing';
    } else if (daysLeft < 5) {
      status = 'status-danger';
    } else if (daysLeft < 12) {
      status = 'status-warning';
    }

    return status;
  }

  getPriceTypes(suppliers, options = {}) {
    if (options.versionPrice && this.state.versionData[0] && this.state.versionData[0].versions) {
      const priceTypesclone = _(
        this.state.versionData[0].versions.map(p => p.priceDetails.map(pd => pd.priceType)),
      )
        .flatMap()
        .value();

      return _.uniqBy(priceTypesclone, '_id');
    } else {
      let priceTypes = [];
      if (suppliers && suppliers.length > 0) {
        let index = suppliers.findIndex(supplier => {
          return supplier.priceDetails && supplier.priceDetails.length > 0;
        });

        if (index != -1) {
          suppliers[index].priceDetails.forEach(priceDetail => {
            priceTypes.push(priceDetail.priceType);
          });
        }
      }
      return priceTypes;
    }
  }

  sortSuppliers(suppliers) {
    if (!(suppliers instanceof Array)) return null;
    return suppliers;
  }

  managePopover(e) {
    const target = e.currentTarget;
    setTimeout(() => {
      $('.popper-wrapper')
        .not(target)
        .each((index, elem) => {
          $(elem)[0].show = false;
        });
    }, 200);
  }

  initiateDropdown() {
    $('.js-toggle-dropdown').click(e => {
      e.stopImmediatePropagation();
      const toToggle = $(e.currentTarget).data('toggle');
      const hasSubmenu = $(e.currentTarget).attr('data-hasSubmenu');
      if (hasSubmenu == 'true') {
        $(toToggle).toggleClass('js-open');
        $(e.currentTarget).toggleClass('is--open');
      } else {
        $(toToggle).addClass('js-open');
        $(toToggle).addClass('is--open');
      }
      if ($(e.currentTarget).data('block-ui')) {
        $(toToggle).parent().append('<div class="gui-blocker"></div>');
        document.getElementsByClassName('gui-blocker')[0].classList.add('open');
      }
    });
  }

  toggleCall(item, event) {
    const currentTarget = event.target;
    if (currentTarget.getAttribute('src') == '/assets/images/call_inactive.png') {
      currentTarget.setAttribute('src', '/assets/images/call_active.png');
      currentTarget.parentElement.classList.add('js-has-called');
      this.dispactchChangeCallIcon(item.supplierId, {
        isCalled: true,
      });
    } else {
      currentTarget.setAttribute('src', '/assets/images/call_inactive.png');
      currentTarget.parentElement.classList.remove('js-has-called');
      this.dispactchChangeCallIcon(item.supplierId, {
        isCalled: false,
      });
    }
  }

  btnAddTextBoxClick(item) {
    this.setState({ selectedItem: item, showPhoneLogModal: true }, () => {
      if (this.phoneLogDiv) {
        // Added a small delay before we scroll in the div because it kept resetting once the div was rendered
        setTimeout(() => {
          const scrollHeight = this.phoneLogDiv.scrollHeight;
          const height = this.phoneLogDiv.clientHeight;
          const maxScrollTop = scrollHeight - height;
          this.phoneLogDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }, 50);
      }
    });
  }

  meetingLi = (val, item) => {
    let meetingVal = item.meeting;
    if (this.state[`meeting${item.supplierId._id}`]) {
      meetingVal = this.state[`meeting${item.supplierId._id}`];
    }
    const customClass = meetingVal === val ? 'ovt-selected' : undefined;
    const _style = { cursor: 'pointer' };
    return (
      <li
        style={_style}
        className={customClass}
        value={meetingVal}
        onClick={() => this.getMeeting(val, item)}
      >
        {val}
      </li>
    );
  };

  getMeeting = (val, item) => {
    this.setState({ [`meeting${item.supplierId._id}`]: val });
    if (this.props.onChangeMeeting) {
      this.props.onChangeMeeting(val, item);
    }
  };

  meetingIcon = item => {
    let meetingIcon = item.meeting;
    if (this.state[`meeting${item.supplierId._id}`]) {
      meetingIcon = this.state[`meeting${item.supplierId._id}`];
    }
    switch (meetingIcon) {
      case 'Bokat möte':
        return '/assets/images/meeting_booked.png';
      case 'Möte avklarat':
        return '/assets/images/meeting_done.png';
      case 'Ej möte':
      default:
        return '/assets/images/meeting_inactive.png';
    }
  };

  getLocalDateTime(givenDate) {
    const utcDate = givenDate.toString();
    // var gmtDateTime = moment.utc(utcDate, "YYYY-MM-DD HH");
    const gmtDateTime = moment.utc(utcDate);
    const local = gmtDateTime.local().format('YYYY-MMM-DD h:mm A');
    return moment(local, 'YYYY-MMM-DD h:mm A').format('llll');
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  onCalendar = (item, v, version) => {
    const length = v.length ? v.length : 0;
    this.setState({
      currentSelectedVersion: version && version.refVersion ? version.refVersion : null,
      selectedVersionLength: length,
      selectedItem: item,
      showDateExtensionModal: true,
    });
  };

  onSave = async data => {
    const { selectedItem, selectedVersionLength = [], currentSelectedVersion } = this.state;
    data.version = selectedVersionLength;
    data.currentVersion = currentSelectedVersion;
    await this.props.onSupplierDate(data, selectedItem);
    this.setState({
      showDateExtensionModal: false,
    });
  };

  calcDaysLeft = date => {
    const dateEnd = this.getLocalDateTime(moment(date).startOf('day').toDate());
    return this.getDaysLeft(dateEnd);
  };

  isEmpty = str => {
    return $.trim($('<div>' + str + '</div>').text()) === '';
  };

  isCalendarActive = date => {
    return this.calcDaysLeft(date) && this.calcDaysLeft(date) != '-';
  };
  
  exportBidCardInfoOnExcel = async () => {
    const { label, suppliers, budgetVal, versionData } = this.state;
    suppliers.map(item => {
      let sup = item.supplierId;
      const version = `version${sup._id}`;
      item.selectedVersion = this.state[version];
    });
    let data = {
      versionData,
      label: label,
      slug: this.props.currentProject.slug,
      suppliers: suppliers,
      areaId: this.props.areaId,
      priceTypes: this.state.priceTypes,
      budgetVal: budgetVal,
    };
    ServiceExcel.exportExcel(data);
    this.setState({ excelOption: 'Exportera till Excel' });
  }

  elBtnAttachClickCallback = _e => {
    this.elInputFile.click();
  };

  async elInputFileChangeCallback(e) {
    var files = e.target.files;
    var re = /(\.xls|\.xlsx|\.xlt|\.xla|\.xltx)$/i;
    if (re.exec(files[0].name)) {
      let slug = this.props.currentProject.slug;
      let file = files[0];
      this.setState({
        excelFiles: files[0],
      });
      const response = await ServiceExcel.uploadExcel(file, slug, this.props.areaId);

      const project = {...this.props.currentProject};
      const projectHasAttachment = !!project.area.find(area => area.areaId._id == this.props.areaId).attachment;
      if (projectHasAttachment) {
        project.area.find(area => area.areaId._id == this.props.areaId).attachment.push(response.data);
      } else {
        project.area.find(area => area.areaId._id == this.props.areaId).attachment = [response.data];
      }
      this.props.setCurrentProject(project);

      NotificationManager.success(`Uppladdning klar`, 'Sparat');
    } else {
      NotificationManager.error(` Det går inte att ladda upp detta ${files[0].name}`, 'Error');
    }
  }

  getFileExtension(file) {
    return file.slice(((file.lastIndexOf('.') - 1) >>> 0) + 2);
  }

  isWinnerAvailable() {
    return this.state.suppliers.some(s => s.reward == true);
  }
  changeStep = (nextStep, skipStepChange) => {
    this.props.changeStepEvent(nextStep, skipStepChange);
  };

  changeSupplierVersion = (selectedVersion, supplierId) => {
    /*
      check if current selected version is same as the version user selected,
      if same, just close the dropdown and return
    */
    const version = `version${supplierId._id}`
    if (selectedVersion == this.state[version]) {
      return;
    }

    // else, set new version as selected and close the dropdown
    this.setState({ [version]: selectedVersion });
  };

  getOptions = (bidLink, extendExpirationDate, quotationVersions, removeSupplier, supplierId) => {
    const { suppliers, step } = this.state;
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    
    const options = [];
    if (bidLink) {
      options.push({
        label: 'Anbudsformulär',
        //For changed org name links the orgSlug will be checked in routes 
        // and redirect to 404 page before even network call so better to 
        // open it in new tab so validateOrgDomain api should run
        // action: () => window.open(`${link}?dist=bd`, '_blank'),
        action: () => this.props.history.push(bidLink, {
          supplierId: supplierId._id
        }),
        icon: <RequestQuoteIcon color='var(--gray-700)' />
      });
    }
    if (supplierId?.organizationNumber) {
      const url = appState._getCurrentOrgUrl();
      options.push({
        label: 'Visa i leverantörsdatabasen',
        action: () => this.props.history.push(`${url}/supplier-database`, { q: supplierId?.organizationNumber }),
        icon: <SearchIcon2 size='18' color='var(--gray-700)' />
      });    
    }
    if (!isReadOnlyUser) {
      if (quotationVersions && quotationVersions.length > 0) {
        options.push({
          label: 'Ändra anbudstid',
          action: extendExpirationDate,
          icon: <MoreTimeIcon color='var(--gray-700)' />
        });
      }

      options.push({
        label: '   leverantör',
        action: removeSupplier,
        icon: <TrashIcon color='var(--gray-700)' />
      });

      const selectedSupplier = suppliers.find(supplier => supplier.reward);
      if (this.props.step > 1) {
        if (!selectedSupplier || selectedSupplier.supplierId._id != supplierId._id) {
          options.push({
            label: 'Välj vinnande bud och gå vidare till förhandling',
            action: () => {
              this.removeWinnerClickCallback();
              this.addWinnerClickCallback(supplierId);
              this.changeStep(3);
            },
            icon: <WinnerIcon />
          });
        } else {
          options.push({
            label: 'Avmarkera vinnande bud',
            action: () => {
              this.removeWinnerClickCallback();
              if (step != 2) {
                this.changeStep(2);
              }
            },
            icon: <WinnerIcon />
          });
        }
      }
    }

    return options;
  };

  shouldStyleSupplierAsNotSelected = (supplier) => {
    return !supplier.reward &&
      this.props.allowEditing &&
      this.isWinnerAvailable();
  }

  getCallDetails = (supplier) => {
    if (supplier && supplier.callDetails && supplier.callDetails.length) {
      const sorted = [...supplier.callDetails]
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map(element => {
          const user = this.props.currentProject.usersFromCallDetails.find(user => user._id == element.userId);
          if (user) {
            return {
              ...element,
              name: user.name,
              avatarUrl: getImageUrl(user.imgId, appState.getCurrentOrgSlug(), FileScope.UserAvatar, { userId: user._id })
            }
          }
          return element;
        });
      return sorted;
    }
    return [];
  }
  renderPhoneLogs = (phoneLogDetails) => {
    return phoneLogDetails && phoneLogDetails.map(element => {
      return (
      <MessageLog key={element.date} name={element.name} avatarUrl={element.avatarUrl} log={element.detail} timestamp={element.date} />
    )})
  }

  getMissingDemands = (supplierBid, version) => {
    const missingDemands = [];
    if (supplierBid) {
      const supplierBidDemands = supplierBid?.demands;
      const demands = version?.demands;
      demands?.forEach(demand => {
        if (!supplierBidDemands?.find(bidDemand => bidDemand?.demandId === demand?.demandId?._id)) {
          missingDemands.push(demand?.demandId);
        }
      })
    }

    return missingDemands;
  }
  getMessage = (supplierBid) => {
    return supplierBid?.message &&
    !(this.isEmpty(supplierBid.message) || supplierBid.message === 'null' || supplierBid.message === null) ? supplierBid.message : '';
  }
  
  getReservations = (supplierBid) => {
    return supplierBid?.content &&
    !(this.isEmpty(supplierBid.content) || supplierBid.content === 'null' || supplierBid.content === null) ? supplierBid.content : '';
  }

  getSupplierDetails = (supplierId) => {
    const { versionData } = this.state;

    if (!supplierId) return null;
    const version = `version${supplierId}`;
    const versionInState = this.state[version];
    const getVersion = !_.isEmpty(versionData) ? versionData[0].versions : undefined;
    if (versionInState || versionInState == 0) {
      const supplierData = getVersion[versionInState].suppliers.find(
        x => x.supplierId === supplierId,
      );
      const supplierBid = getVersion[versionInState].supplierBids.find(x =>
        x.supplierId === supplierId ? x : null,
      );

      const supplierSlug = this.state.suppliers.find(supplier => supplier.supplierId._id == supplierId).supplierId.slug;
      return {
        intentStatus: supplierData?.intentStatus,
        bidLink: `/${appState._getCurrentOrgSlug()}/projects/${this.props.currentProject.slug}/${this.props.areaSlug}/bid-forms/${supplierSlug}`,
        isSeen: supplierData?.isSeen,
        missingDemands : this.getMissingDemands(supplierBid, getVersion[versionInState]) || [],
        reservations: this.getReservations(supplierBid),
        attachments: supplierBid?.attachments ?? [],
        message: this.getMessage(supplierBid),
        quotationValidity: supplierBid?.quotationValidity,
        isSubmitted: supplierBid?.priceDetails,
        date: getVersion[versionInState].quotationExpiryDate,
      };
    }
  }


  renderSuppliers = () => {
    const { suppliers, versionData, showOnlyWinningSupplier, showSupplierThatDeclined } = this.state;

    const isReadOnlyUser = appState.isReadOnlyAccessRole();

    const modifiedSuppliers = suppliers.map(supplier => {
      const supplierDetails = this.getSupplierDetails(supplier?.supplierId?._id);
      return {
        ...supplier,
        bidIntentStatus: supplierDetails?.intentStatus,
        bidLink: supplierDetails?.bidLink,
        missingDemands: supplierDetails?.missingDemands,
        reservations: supplierDetails?.reservations,
        message: supplierDetails?.message,
        attachments: supplierDetails?.attachments ?? [],
        isSeen: supplierDetails?.isSeen,
        quotationValidity: supplierDetails?.quotationValidity,
        isSubmitted: supplierDetails?.isSubmitted,
        date: supplier.date ?? supplierDetails?.date,
      }
    }).filter(supplier => {
      const stepHasWinningSupplier = this.props.step == 3 || this.props.step == 4;
      if (stepHasWinningSupplier) {
        if (showOnlyWinningSupplier && !supplier.reward) {
          return null;
        }
      } else {
        if (!showSupplierThatDeclined && supplier?.bidIntentStatus?.intent === BidIntentStatus.DECLINED) {
          return null;
        }
      }
      return supplier;
    });
    const showDefaultBidValidityDate = modifiedSuppliers.some(supplier => this.getQuoteValidity(supplier?.quotationValidity))

    const getHiddenWinningSuppliersNotifications = () => {
      let notificationCount = 0;
      suppliers?.forEach(s => {
        if(!s.reward && s?.supplierId?.notification){
          notificationCount += s?.supplierId?.notification?.reduce((count, curr) => (count + curr.count), 0);
        }
      })
      return notificationCount;
    }

    const getHiddenDeclinedSupplierNotification = () => {
      let notificationCount = 0;
      suppliers?.forEach(s => {
        const supplierDetails = this.getSupplierDetails(s?.supplierId?._id);
        if(supplierDetails?.intentStatus?.intent == "DECLINED" && s?.supplierId?.notification){
          notificationCount += s?.supplierId?.notification?.reduce((count, curr) => (count + curr.count), 0);
        }
      })
      return notificationCount;
    } 

    const hideShowNotSelectedSuppliersControl = () => {
      const { step } = this.props;
      const { showOnlyWinningSupplier, showSupplierThatDeclined, suppliers } = this.state;

      const numOfHiddenSuppliers = suppliers.length - modifiedSuppliers?.length;
      const hiddenWinningSupplierNotification = getHiddenWinningSuppliersNotifications();
      const hiddenDeclinedSupplierNotification = getHiddenDeclinedSupplierNotification();
      
      if (suppliers.length > 0) {
        if (step == 3 || step == 4) {
          return (numOfHiddenSuppliers > 0 || !showOnlyWinningSupplier) && (
            <p className='supplierViewControls'>
              {showOnlyWinningSupplier && `${suppliers.length - 1} ${suppliers.length - 1 > 1 ? "andra leverantörer" : "annan leverantör"} `}
              <span onClick={() => this.setState({ showOnlyWinningSupplier: !showOnlyWinningSupplier })} className="hiddenSupplierLink">
                {showOnlyWinningSupplier ? 'Visa' : 'Visa endast vinnande'}
                {showOnlyWinningSupplier && <NotificationCounter className="notificationBadge" counter={hiddenWinningSupplierNotification} />}
              </span>
            </p>
          )
        }
        return (numOfHiddenSuppliers > 0 || showSupplierThatDeclined) && (
          <p className='supplierViewControls'>
              {!showSupplierThatDeclined && ` ${numOfHiddenSuppliers} leverantör${numOfHiddenSuppliers > 1 ? 'er' : ''} har tackat nej `}
            <span onClick={() => this.setState({ showSupplierThatDeclined: !showSupplierThatDeclined })} className='hiddenSupplierLink'>
              {!showSupplierThatDeclined ? 'Visa' : 'Dölj leverantörer som tackat nej'}
              {!showSupplierThatDeclined && <NotificationCounter className="notificationBadge" counter={hiddenDeclinedSupplierNotification} />}
            </span>
          </p>
        )
      }
    }

    return (
      <>
        <div className=''>
          <div className='suppliersLeftPart'>
            {modifiedSuppliers.map((supplier) => {
              const { supplierId } = supplier;

              if (!supplierId) return null;
              const isBlacklist = supplierId.isBlacklist ? supplierId.isBlacklist : false;
              const blacklistReason = supplierId.blacklistReason ? supplierId.blacklistReason : [];
              const meetingOptions = ['Bokat möte', 'Möte avklarat', 'Ej möte'];

              const version = `version${supplierId._id}`;
              const versionInState = this.state[version];

              let quotationVersions = [];
              let eyeIcon = supplier?.isSeen;
              let bidLink = supplier?.bidLink;
              let bidIntent = supplier?.bidIntentStatus;

              const getVersion = !_.isEmpty(versionData) ? versionData[0].versions : undefined;

              // old quotation eye icon fix!
              let isOldQuotation;

              // push all relative versions of the supplier
              if (!_.isEmpty(getVersion)) {
                for (const qv of getVersion) {
                  for (const s of qv.suppliers) {
                    if (s.supplierId === supplierId._id) quotationVersions.push(qv);
                  }
                }

                if (versionInState || versionInState == 0) {
                  if (!eyeIcon && isOldQuotation) eyeIcon = supplier?.isSeen;

                  isOldQuotation = moment(getVersion[versionInState].quotationDate).isBefore(
                    '2020-04-13',
                  );
                }
              }

              quotationVersions = this.removeDuplicates(quotationVersions, '_id');

              const hasCallDetails = supplier.callDetails && supplier.callDetails.length > 0;

              const removeSupplier = async () => {
                const { _id, name, notification } = supplier.supplierId;
                if (window.confirm('Är du säker på att du vill radera denna leverantör ' + name + '?')) {
                  const { currentProject } = this.props;
                  try {
                    const projectSlug = currentProject.slug;

                    await ServiceProjects.dbRemoveSupplier(projectSlug, this.props.areaId, _id);
                    if (notification && notification.length) notification.map(supMember => clearNotification(supMember));
                    const areaSuppliers = this.state.suppliers.filter(supplier => supplier.supplierId._id != _id).map(supplier => ({ supplierId: supplier.supplierId }));
                    ServiceProjects.replaceAreaSuppliersBySlug(projectSlug, this.props.areaId, areaSuppliers);
                    if (areaSuppliers?.length === 0) {
                      this.setState({ suppliers: [] });
                    }
                  } catch (error) {
                    console.log("Error on removing supplier==>", error)
                  }
                }
              };

              const clearNotification = notObj => {
                if (!notObj || !Object.keys(notObj).length) return;

                const { currentProject } = this.props;
                const currentProjectAreas = currentProject && currentProject.area;

                if (!currentProjectAreas || !currentProjectAreas.length) return;

                currentProjectAreas.map(area => {
                  const { areaId, suppliers } = area;

                  if (areaId && areaId.notification && areaId.notification.length) {
                    const areaIndex = areaId.notification.findIndex(
                      el => el.supplierMemberId === notObj.supplierMemberId && el.areaId === notObj.areaId,
                    );

                    if (areaIndex >= 0) areaId.notification.splice(areaIndex, 1);
                  }

                  if (suppliers && suppliers.length) {
                    for (let i = 0; i < suppliers.length; i++) {
                      const sup = suppliers[i];
                      const { supplierId } = sup;

                      if (supplierId && supplierId.notification && supplierId.notification.length) {
                        const supIndex = supplierId.notification.findIndex(
                          el => el.supplierMemberId === notObj.supplierMemberId && el.areaId === notObj.areaId,
                        );

                        if (supIndex >= 0) supplierId.notification.splice(supIndex, 1);
                      }
                    }
                  }

                  return area;
                });
              };
              const getMeetingStatusColor = () => {
                let meetingIcon = supplier.meeting;
                if (this.state[`meeting${supplierId._id}`]) {
                  meetingIcon = this.state[`meeting${supplierId._id}`];
                }
                switch (meetingIcon) {
                  case 'Bokat möte':
                    return 'var(--yellow-500)';
                  case 'Möte avklarat':
                    return 'var(--green-500)';
                  case 'Ej möte':
                  default:
                    return undefined;
                }
              };

              const getToolbarActions = () => {
                const renderMailIcon = () => {
                  const notificationCount = supplierId.notification && supplierId.notification.reduce((prev, curr) => (
                    { count: prev.count + curr.count }
                  ), { count: 0 }).count;
                  return (
                    <div className='mailIconContainer'>
                      {notificationCount > 0 && <div>{notificationCount}</div>}
                      <MailIcon />
                    </div>
                  )
                };
                const meetingIcon = (
                  <OptionsMenu isMenuLeft style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }} options={
                    meetingOptions.map(option => ({
                      label: option,
                      action: () => this.getMeeting(option, supplier),
                    }))
                  }>
                    <GroupIcon color={getMeetingStatusColor()} />
                  </OptionsMenu>
                );
                const phoneIcon = (
                  <PhoneIcon color={hasCallDetails ? 'var(--cyan-500)' : undefined} />
                );

                const url = appState._getCurrentOrgUrl();

                const linkToSupplierChat = `${url}/projects/${this.props.currentProject.slug}/${this.props.areaSlug}/${supplierId.slug}`;
                const actions = [
                  { title: 'Meddelanden – Skicka mail till leverantören, läs inkommande meddelanden och anbud.', component: renderMailIcon(), action: () => this.props.history.push(linkToSupplierChat) },
                  { title: 'Mötesstatus – Grön ikon = avklarat möte, gul ikon = bokat möte.', component: meetingIcon, disabledFully: isReadOnlyUser },
                  { title: 'Telefonlogg – Blå ikon indikerar att något är loggat.', component: phoneIcon, action: () => this.btnAddTextBoxClick(supplier) },
                ];

                if (supplierId?.organizationNumber) {
                  actions.push({
                    title: 'Visa i leverantörsdatabasen',
                    action: () => this.props.history.push(`${url}/supplier-database`, { q: supplierId?.organizationNumber }),
                    component: <SearchIcon2 size='18' color='var(--gray-700)' />
                  });
                  // const organizationHasSyna = appState.getOrgFromUrl()?.hasSyna;
                  // if (organizationHasSyna) {
                  //   actions.push({
                  //     title: 'Monitoring events',
                  //     action: () => this.props.history.push(`${url}/supplier-monitoring`, {
                  //       supplier: {
                  //         name: supplierId.name,
                  //         organizationNumber: supplierId.organizationNumber
                  //       }
                  //     }),
                  //     component: <MonitoringHeartIcon />
                  //   });
                  // }
                }

                const selectedSupplier = suppliers.find(supplier => supplier.reward);

                if (this.props.step > 1) {
                  if (!selectedSupplier || selectedSupplier.supplierId._id != supplierId._id) {
                    actions.push({
                      title: 'Välj vinnande bud och gå vidare till förhandling',
                      component: (<div onClick={() => {
                        this.removeWinnerClickCallback();
                        this.addWinnerClickCallback(supplierId);
                        this.changeStep(3);
                      }}>
                        <WinnerIcon size="16" color={forbiddenSupplier ? "var(--gray-400)" : "var(--gray-700)"} />
                      </div>),
                      disabledFully: forbiddenSupplier,
                    });
                  } else {
                    actions.push({
                      title: 'Avmarkera vinnande bud',
                      action: () => {
                        this.removeWinnerClickCallback();
                        if (this.props.step != 2) {
                          this.changeStep(2);
                        }
                      },
                      component: <WinnerIcon size="16" color="var(--yellow-500)" />
                    });
                  }
                }

                if (!isReadOnlyUser) {
                  actions.push({
                    title: 'Ta bort leverantör',
                    action: removeSupplier,
                    component: <TrashIcon size="16" color='var(--gray-700)' />
                  });
                }

                return actions;
              };

              const decision = supplierId?.prequalification?.decision;
              const forbiddenSupplier = isBlacklist || (decision == PrequalificationDecision.INBOX || decision == PrequalificationDecision.REJECTED);

              return (
                <div key={supplierId._id} className={`${this.shouldStyleSupplierAsNotSelected(supplier) ? 'notSelectedSupplier' : ''}`} style={{display:"block"}}>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "12px 0", borderBottom: "1px solid var(--gray-200)"}}>
                    <div className='supplierRowHeader' style={{color: forbiddenSupplier ? "var(--gray-400)" : "var(--gray-700)"}}>
                      {isBlacklist && (
                        <TooltipContainer renderReferenceComponent={ (className, ref) =>
                          <BlockIcon ref={ref} className={`${className} bid-card-blackList-icon`} />
                        }>
                          {blacklistReason?.length > 0 && <SupplierBlockedReasons reasons={blacklistReason} />}
                        </TooltipContainer>
                      )}
                      {(decision == PrequalificationDecision.INBOX || decision == PrequalificationDecision.REJECTED) && (
                        <TooltipContainer renderReferenceComponent={ (className, ref) =>
                          <BlockIcon ref={ref} className={`${className} bid-card-blackList-icon`} />
                        }>
                          <div style={{color: "var(--gray-700)", fontWeight: "400", maxWidth: "20em", whiteSpace: "normal"}}>
                            <p style={{color: "var(--gray-900)", fontWeight: "500"}}>Leverantörsansökan ej godkänd.</p>
                            Ansökan måste granskas av en administratör innan leverantören används i projekt.
                          </div>
                        </TooltipContainer>
                      )}
                      <p className="supplier-name" title={supplierId.name} style={{ display: 'flex' }}>
                        {supplierId.frameworkAgreement && (supplierId.frameworkAgreement.file || supplierId.frameworkAgreement.validUntilDate || supplierId.frameworkAgreement.comment) && 
                          <ContractIcon style={{ marginRight: "10px", marginLeft:0, width: "20px", verticalAlign: "middle" }} />
                        }
                        <span>{supplierId.name}</span>
                      </p>
                    </div>
                    <div className='supplierRowActions'>
                      <Toolbar actions={getToolbarActions()} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {hideShowNotSelectedSuppliersControl(suppliers?.length - modifiedSuppliers?.length)}
      </>
    )
  };

  renderRequestsAndBids = (buttons) => {
    const { suppliers, versionData, showOnlyWinningSupplier, showSupplierThatDeclined } = this.state;

    const isReadOnlyUser = appState.isReadOnlyAccessRole();

    let modifiedSuppliers = suppliers.map(supplier => {
      const supplierDetails = this.getSupplierDetails(supplier?.supplierId?._id);
      return {
        ...supplier,
        bidIntentStatus: supplierDetails?.intentStatus,
        bidLink: supplierDetails?.bidLink,
        missingDemands: supplierDetails?.missingDemands,
        reservations: supplierDetails?.reservations,
        message: supplierDetails?.message,
        attachments: supplierDetails?.attachments ?? [],
        isSeen: supplierDetails?.isSeen,
        quotationValidity: supplierDetails?.quotationValidity,
        isSubmitted: supplierDetails?.isSubmitted,
        date: supplier.date ?? supplierDetails?.date,
      }
    }).filter(supplier => {
      const { supplierId } = supplier;
      const version = `version${supplierId._id}`;
      const versionInState = this.state[version];
      return versionInState || versionInState == 0;
    }).sort((a, b) => a - b);
    const showDefaultBidValidityDate = modifiedSuppliers.some(supplier => this.getQuoteValidity(supplier?.quotationValidity))

    const getHiddenWinningSuppliersNotifications = () => {
      let notificationCount = 0;
      suppliers?.forEach(s => {
        if(!s.reward && s?.supplierId?.notification){
          notificationCount += s?.supplierId?.notification?.reduce((count, curr) => (count + curr.count), 0);
        }
      })
      return notificationCount;
    }

    const getHiddenDeclinedSupplierNotification = () => {
      let notificationCount = 0;
      suppliers?.forEach(s => {
        const supplierDetails = this.getSupplierDetails(s?.supplierId?._id);
        if(supplierDetails?.intentStatus?.intent == "DECLINED" && s?.supplierId?.notification){
          notificationCount += s?.supplierId?.notification?.reduce((count, curr) => (count + curr.count), 0);
        }
      })
      return notificationCount;
    } 

    const hideShowNotSelectedSuppliersControl = () => {
      const { step } = this.props;
      const { showOnlyWinningSupplier, showSupplierThatDeclined, suppliers } = this.state;

      const numOfHiddenSuppliers = suppliers.length - modifiedSuppliers?.length;
      const hiddenWinningSupplierNotification = getHiddenWinningSuppliersNotifications();
      const hiddenDeclinedSupplierNotification = getHiddenDeclinedSupplierNotification();
      
      if (suppliers.length > 0) {
        if (step == 3 || step == 4) {
          return (numOfHiddenSuppliers > 0 || !showOnlyWinningSupplier) && (
            <p className='supplierViewControls'>
              {showOnlyWinningSupplier && `${suppliers.length - 1} ${suppliers.length - 1 > 1 ? "andra leverantörer" : "annan leverantör"} `}
              <span onClick={() => this.setState({ showOnlyWinningSupplier: !showOnlyWinningSupplier })} className="hiddenSupplierLink">
                {showOnlyWinningSupplier ? 'Visa' : 'Visa endast vinnande'}
                {showOnlyWinningSupplier && <NotificationCounter counter={hiddenWinningSupplierNotification} />}
              </span>
            </p>
          )
        }
        return (numOfHiddenSuppliers > 0 || showSupplierThatDeclined) && (
          <p className='supplierViewControls'>
              {!showSupplierThatDeclined && ` ${numOfHiddenSuppliers} leverantör${numOfHiddenSuppliers > 1 ? 'er' : ''} har tackat nej `}
            <span onClick={() => this.setState({ showSupplierThatDeclined: !showSupplierThatDeclined })} className='hiddenSupplierLink'>
              {!showSupplierThatDeclined ? 'Visa' : 'Dölj leverantörer som tackat nej'}
              {!showSupplierThatDeclined && <NotificationCounter counter={hiddenDeclinedSupplierNotification} />}
            </span>
          </p>
        )
      }
    }

    if (modifiedSuppliers.length > 0) {
      return (
        <Card title="Förfrågningar och anbud" gap={16}>
          <BidOverview 
            modifiedSuppliers={modifiedSuppliers}
            versionData={versionData}
            showDefaultBidValidityDate={showDefaultBidValidityDate}
            removeDuplicates={this.removeDuplicates.bind(this)}
            onCalendar={this.onCalendar.bind(this)}
            changeSupplierVersion={this.changeSupplierVersion.bind(this)}
            getVersionInState={supplierId => {
              const key = `version${supplierId}`;
              return this.state[key];
            }}
            calcDaysLeft={this.calcDaysLeft.bind(this)}
            currentProject={this.props.currentProject}
            areaSlug={this.props.areaSlug}
            areaName={this.props.label}
          />
          {buttons}
        </Card>
      );
    } else {
      return <></>;
    }
  };

  isOldVersion = (supplier) => {
    const supplierId = supplier.supplierId._id
    const versionField = `version${supplierId}`;
    if (this.state[versionField] >= 0) {
      let quoteVersions = _.head(this.state.versionData);
      const versionsForSupplier = quoteVersions.versions.filter(v => {
        return v.suppliers.find(s => { return s.supplierId === supplierId })
      });

      let maxVersionForSupplier = versionsForSupplier.map(v => v.refVersion).reduce((a, b) => (Math.max(a, b)), 0);
      return this.state[versionField] < maxVersionForSupplier;
    }
    return false;
  }

  renderTeamBuilder = (members) => {
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    const shouldNotRender = (!isReadOnlyUser && this.state.areaUsers.length < MAX_TEAM_SIZE) || (members && members.length > 0);
    if (shouldNotRender) {
      return (
        <TitleWrapper title='Ansvarig'>
          <TeamBuilder users={this.props.users} areaUsers={members} setAreaUsers={(areaUsers) => this.setState({ areaUsers })} currentProject={this.props.currentProject} areaId={this.props.areaId} />
        </TitleWrapper>
      )
    } else return null;
  }

  removeArea = async () => {
    const id = this.props.areaId;
    const name = this.props.label;
    const message = `Är du säker på att du vill radera ${name}?`;
    if (window.confirm(message)) {
      await ServiceProjects.removeProjectArea(this.props.currentProject.slug, id);
      this.props.refreshCurrentProject();
    }
  }

  getPhaseOptions = () => {
    const { suppliers } = this.state;
    const shouldHaveReadOnly = appState.isReadOnlyAccessRole();
    if (this.props.areaStep == 1) {
      return [{ label: 'Utvärdera anbud', action: () => this.changeStep(2) }]
    } else if (this.props.areaStep == 2) {
      const options = [{ label: 'Gör om förfrågan', action: () => this.changeStep(1) }];
      if (!shouldHaveReadOnly) {
        options.push({ label: 'Välj vinnande anbud', action: () => {
          if (suppliers.find(supplier => supplier.reward)) {
            this.changeStep(3);
          } else {
            this.setState({ showWinnerModal: true })
          }
        }});
      }
      return options;
    } else if (this.props.areaStep == 3 || this.props.areaStep == 4) {
      return [{ label: 'Gör om utvärderingen', action: () => {
        this.changeStep(2);
        this.removeWinnerClickCallback();
      }}]
    }
    return [];
  }
  getVersionOptions = () => {
    return this.props.quotations
      && this.props.quotations.map(q => ({
        label: `Version ${q.version + 1}`, 
        action: () => !appState.isReadOnlyAccessRole() ? this.props.createNewVersion(this.props.areaSlug, q.version) : {},
        quotationDate: formatDate(this.state.versionData?.[0]?.versions?.find(v => v.refVersion == q.version)?.quotationDate),
      }));
  }
  getAttachmentVersions = () => {
    return this.state.attachments
      && this.state.attachments.map((q, index) => ({
        key: this.state.attachments[index]?.file,
        label: this.state.attachments[index]?.fileName,
        action: async () => {
          const file = this.state.attachments[index];
          const filename = file.file.split('.').slice(0, -1).join('.');
          const extension = this.getFileExtension(file.file);
          await ServiceDownload.downloadFile(filename, extension, file.fileName, FileScope.ProjectEvaluation, { projectId: this.props.currentProject._id, areaId: this.props.areaId });
        }}
      ));
  }
  getCopyOptions = () => {
    const list = this.props.currentProject?.area?.map(a => ({
      name: a.areaId.name, 
      slug: a.areaId.slug,
      hasVersion: a.quotations && a.quotations.length > 0,
      lastVersion: a.quotations && a.quotations.length > 0 && a.quotations[a.quotations.length - 1].version,
      versions: a.quotations && a.quotations.length > 0 && a.quotations.map(q => q.version),
    }));
    return list.filter(a => a.hasVersion).reduce((all, a) => all.concat(a.versions.toReversed().map(v => ({
      label: a.name + ", version " + (v + 1),
      value: "?source=" + a.slug + ":" + (v + 1),
    }))), []);
  }

  renderPhaseTransitionDropdown = () => {
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    const { areaStep, wizard } = this.props;
    const selectedStep = wizard.find(element => areaStep == element.step);

    return !isReadOnlyUser && (
      <TitleWrapper title='Status'>
        <OptionsMenu options={this.getPhaseOptions()}>
          <BtButton rightIcon={<ArrowIcon />} class='gray'>
            {selectedStep.name}
          </BtButton>
        </OptionsMenu>
      </TitleWrapper>
    )
  }
  renderBudgetInput = () => {
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    const { changeAreaBudget } = this.props;
    const { budgetVal } = this.state;
    const budgetValFormatted = formatAmount(budgetVal);
    return (!isReadOnlyUser || budgetValFormatted) && (
      <TitleWrapper title='Budget'>
        <div className="bitcard-budget-pricetype-row">
          <div className={`bitcard-budget-section`}>
            <Field
              className={`${isReadOnlyUser && budgetValFormatted ? 'readOnlyAccess' : ''} budgetFieldOnSinglePurchasePage`}
              placeholder='Skriv med siffror'
              value={budgetValFormatted}
              onChange={value => this.setState({ budgetVal: value })}
              onBlur={e => changeAreaBudget(e)}
              disabled={isReadOnlyUser}
              unit='kr'
            />
          </div>
        </div>
      </TitleWrapper>
    )
  }
  renderFirstStepControls = () => {
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    const url = appState._getCurrentOrgUrl();
    const { step, createNewVersion, areaSlug, history, currentProject } = this.props;
    const { suppliers, showAllVersions } = this.state;

    const versionOptions = (this.getVersionOptions() ?? []).reverse();
    const shouldShowTitle = suppliers && suppliers.length > 0 && (step == 1 || step == 2 || (versionOptions && versionOptions.length > 0));

    
    const shownVersionOptions = showAllVersions ? versionOptions : versionOptions.slice(0,3);
    return shouldShowTitle && ( 
      <Card title="Förfrågningsunderlag" gap={16}>
        {shownVersionOptions.length > 0 ? (
          <div style={{display: "flex", flexDirection: "column", gap: 16}}>
            <table className={styles.documents}>
              <thead>
                <tr>
                  <th>Version</th>
                  <th>Skickad</th>
                </tr>
              </thead>
              <tbody>
                {shownVersionOptions?.map(v => (
                  <tr key={v.label} onClick={v.action}>
                    <th>{v.label}</th>
                    <td>{v.quotationDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {versionOptions.length > 3 && (
              (showAllVersions ? (
                <div className={styles.documentLink} style={{textAlign: "center"}} onClick={() => this.setState({showAllVersions: false})}>Dölj gamla versioner</div>
              ) : (
                <div className={styles.documentLink} style={{textAlign: "center"}} onClick={() => this.setState({showAllVersions: true})}>Visa alla versioner</div>
              ))
            )}
          </div>
        ) : (
          <div className={styles.noVersions}>Det finns inga förfrågningsunderlag, skapa ett med knappen nedan.</div>
        )}
        <div style={{flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
          <div className='secondRowBidCardControls' style={{flexWrap: "wrap"}}>
            {!isReadOnlyUser && step == 1 && (
              <>
                <BtButton
                  leftIcon={<AddIcon color='var(--white)'/>}
                  style={{ width: 'fit-content' }}
                  onClick={() => createNewVersion(areaSlug)}
                  class='dark'
                >
                  Nytt förfrågningsunderlag
                </BtButton>
                <BtButton
                  leftIcon={<AddIcon color='var(--gray-700)'/>}
                  style={{ width: 'fit-content' }}
                  onClick={() => this.setState({ showCopyModal: true })}
                  color="gray"
                  disabled={!this.props.currentProject.area.find(a => a.quotations && a.quotations.length > 0)}
                >
                  Kopiera befintligt
                </BtButton>
                <Modal
                  show={this.state.showCopyModal}
                  setShow={(value) => this.setState({ showCopyModal: value, copySource: null })}
                  title='Kopiera förfrågningsunderlag'
                  buttonInfo={{ 
                    label: "Skapa kopia", 
                    action: () => { createNewVersion(areaSlug, undefined, this.state.copySource?.value )}, 
                    disabled: !this.state.copySource,
                  }}
                >
                  <Selector
                    label="Välj källa"
                    options={this.getCopyOptions()}
                    value={this.state.copySource}
                    onChange={(value => this.setState({ copySource: value }))}
                  />
                </Modal>
              </>
            )}
            
            {/*<OptionsMenu options={versionOptions}>
              <BtButton
                rightIcon={<ArrowIcon />}
                style={{ width: step == 1 ? 'fit-content' : '190px' }}
                class='gray'
              >
                <i className="far fa-folder-open"></i> Öppna
              </BtButton>
            </OptionsMenu>*/}
            
            <BtButton
              style={{ width: 'fit-content' }}
              onClick={() => history.push(`${url}/projects/${currentProject.slug}/${areaSlug}/tips`)}
              class='gray'
            >
              Upphandlingstips
            </BtButton>
          </div>
        </div>
      </Card>
    )
  }
  renderSecondStepControls = () => {
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    const { step } = this.props;
    const { attachments } = this.state;
    return ((!isReadOnlyUser && step == 2) || (attachments && attachments.length > 0)) && (
      <>{/*
        <Card title='Anbudsutvärderingar' gap={16}>
        {this.getAttachmentVersions().map(v => (
          <div className={styles.documentLink} onClick={v.action}><img className="excel-icon" src={ExcelIcon} /> {v.label}</div>
        ))}
        */}

          <div className='secondRowBidCardControls'>
          {!isReadOnlyUser && step == 2 && (
            <>
              <input
                accept=".xls,.xlsx,.xlt,.xla,.xltx"
                style={{ display: 'none' }}
                type="file"
                multiple
                onClick={event => {
                  event.target.value = null;
                }}
                onChange={this.elInputFileChangeCallback.bind(this)}
                ref={el => (this.elInputFile = el)}
              />
              <BtButton
                style={{ width: 'fit-content' }}
                leftIcon={<img className="excel-icon" src={ExcelIcon} />}
                onClick={() => this.exportBidCardInfoOnExcel()}
                class='gray'
              >
                Exportera till Excel
              </BtButton>
              <BtButton
                style={{ width: 'fit-content' }}
                leftIcon={<UploadIcon color='var(--gray-700)' />}
                onClick={() => this.elBtnAttachClickCallback()}
                class='gray'
              >
                Ladda upp utvärdering
              </BtButton>
            </>
          )}
          {attachments && attachments.length > 0 && (
            <OptionsMenu options={this.getAttachmentVersions()}>
              <BtButton
                style={{ width: (step == 3 || step == 4) ? '190px' : 'fit-content' }}
                leftIcon={<img className="excel-icon" src={ExcelIcon} />}
                rightIcon={<ArrowIcon />}
                class='gray'
              >
                Öppna utvärdering
              </BtButton>
            </OptionsMenu>
          )}
        </div>
      </>
    )
  }
  renderThirdStepControls = () => {
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    const { step, uploadContract, createContract } = this.props;
    const val = this.props.currentProject.area.find(a => a.areaId._id == this.props.areaId);

    const getContractStatusDot = (contract, isManualContract) => {
      let color = 'var(--yellow-500)';
      let char = '⋯'
      if (isManualContract) {
        color = 'var(--gray-400)';
        char = '•';
      } else {
        const { lastStatus, signedAt } = contract || {};

        if (signedAt) {
          color = 'var(--green-500)';
          char = '✓';
        } else if (lastStatus === 'canceled' || lastStatus === 'timedout' || lastStatus === 'rejected' || lastStatus === 'document_error') {
          color = 'var(--red-500)';
          char = '✗';
        }
      }
      
      return <span style={{ color, width: '1.5em', display: 'inline-block', textAlign: 'center' }}>{char}</span>
    }

    const getContractStatusText = (contract, isManualContract) => {
      if (isManualContract) return "Manuellt signerat";
      if (contract.signedAt) return `E-signerat ${formatDate(contract.signedAt)}`;
      const { lastStatus } = contract;
      if (lastStatus === 'canceled' || lastStatus === 'timedout' || lastStatus === 'rejected' || lastStatus === 'document_error') {
        return {
          canceled: "Återkallat",
          timedout: "Utgånget",
          rejected: "Nekat",
          document_error: "Dokumentfel",
        }[lastStatus];  
      }
      return "Väntar på signering";
    };

    const contracts = (val.contracts || []).map(c => ({
      label: <span>{getContractStatusDot(c)} {shortFilename(c.fileName, 50)}</span>, 
      action: () => this.showContractStatus(c),
      contract: c,
      key: c.documentId,
      status: getContractStatusText(c, false),
      sent: formatDate(c.createdAt),
    }));
    const manualContracts = (this.props.manualContract || []).map(c => ({
      label: <span>{getContractStatusDot(c, true)} {shortFilename(c.fileName, 50)}</span>,
      action: () => this.showContractStatus(c),
      key: c._id,
      status: "Manuellt signerat",
      sent: formatDate(c.createdAt),
    }));
    const allContracts = [
      ...contracts,
      ...manualContracts
    ];

    return ((!isReadOnlyUser && step == 3) || allContracts.length > 0) && (
      <Card title='Avtal' gap={16}>
        {allContracts.length > 0 && (
          <table className={styles.documents}>
            <thead>
              <tr>
                <th>Namn</th>
                <th>Status</th>
                <th>Skapad</th>
              </tr>
            </thead>
            <tbody>
              {allContracts.map(v => (
                <tr onClick={v.action} key={v.key}>
                  <th>{v.label}</th>
                  <td>{v.status}</td>
                  <td>{v.sent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!isReadOnlyUser && step >= 3 && (
          <div className="secondRowBidCardControls">
            <BtButton leftIcon={<PlusIcon/>} class="gray" onClick={createContract}>Lägg till och e-signera med Scrive</BtButton>
            <BtButton leftIcon={<PlusIcon/>} class="gray" onClick={uploadContract}>Lägg till manuellt signerat</BtButton>
          </div>
        )}
        {/*<div className='secondRowBidCardControls'>
          <OptionsMenu options={contractOptions} isMenuLeft>
            <BtButton rightIcon={<ArrowIcon />} class='gray'>
              {`Avtal${allContracts.length > 0 ? ` (${allContracts.length})` : ''}`}
            </BtButton>
          </OptionsMenu>
        </div>*/}
      </Card>
    )
  }

  async showContractStatus(contract) {
    this.setState({
      selectedContract: contract,
      showAgreementModal: true,
      agreementModalKey: this.state.agreementModalKey + 1,
    });
  }


  render() {
    let dateEnd = this.getLocalDateTime(moment(this.props.dateEnd).startOf('day').toDate());
    let suppliers = this.state.suppliers;
    let supplierWinner = suppliers.find(s => s.reward);
    let daysLeft = this.getDaysLeft(dateEnd);
    let status = this.getStatus(daysLeft, supplierWinner);
    
    const {
      selectedItem,
      phoneLogValue,
      showPhoneLogModal,
      areaUsers,
      showWinnerModal,
      winnerValue,
      temporaryAreaLeadTime,
    } = this.state;
    dateEnd = this.parseDateEnd(dateEnd);
    if (dateEnd && typeof dateEnd == 'number') dateEnd = new Date(dateEnd);
    if (!(dateEnd instanceof Date)) dateEnd = null;

    const isReadOnlyUser = appState.isReadOnlyAccessRole();

    const defaultPhoneLogs = [
      { label: 'Leverantör svarade inte', value: 'Leverantör svarade inte' },
      { label: 'Påminde leverantören', value: 'Påminde leverantören' },
      { label: 'Förberedde för förfrågan', value: 'Förberedde för förfrågan' },
    ];

    const phoneLogDetails = this.getCallDetails(selectedItem);

    const area = this.props.currentProject.area.find(area => area.areaId._id == this.props.areaId);

    const quotationSentBy = area?.quotationSentBy && moment(area.quotationSentBy);
    const answersLatestBy = area?.answersLatestBy && moment(area.answersLatestBy);
    const purchaseCompleted = area?.purchaseCompleted && moment(area.purchaseCompleted);
    const productionStartsAt = area?.productionStartsAt && moment(area?.productionStartsAt);
    const productionEndsAt = area?.productionEndsAt && moment(area?.productionEndsAt);
    const areaLeadTime = (area?.leadTime || area?.leadTime === 0) ? area?.leadTime : undefined;
    const { value, updating } = temporaryAreaLeadTime[area.areaId._id] || {};
    const temporaryLeadTime = updating ? value : areaLeadTime;
    const leadTimeInWeeks = (temporaryLeadTime || temporaryLeadTime === 0) ? temporaryLeadTime / (1000 * 60 * 60 * 24 * 7) : undefined;
    let leadTimeFieldValue = '';

    if (leadTimeInWeeks === 0) {
      leadTimeFieldValue = '0';
    } else if (leadTimeInWeeks) {
      leadTimeFieldValue = formatAmount(leadTimeInWeeks);
    }

    let errorMessage = '';
    if (productionStartsAt && purchaseCompleted && areaLeadTime) {
      const calculatedLeadTime = moment(productionStartsAt).diff(purchaseCompleted, 'milliseconds');
      const calculatedLeadTimeInWeeks = calculatedLeadTime / (1000 * 60 * 60 * 24 * 7);
      const leadTimeInWeeks = areaLeadTime / (1000 * 60 * 60 * 24 * 7);
      const leadTimesDontMatch = calculatedLeadTimeInWeeks < leadTimeInWeeks;
      const purchaseCompletedDateIsAfterProductionsStartsDate = moment(purchaseCompleted).isSameOrAfter(productionStartsAt);
      errorMessage = (leadTimesDontMatch || purchaseCompletedDateIsAfterProductionsStartsDate) ? (
        <div style={{ maxWidth: '200px', whiteSpace: 'normal' }}>
          Inte tillräckligt med tid mellan inköp klart och leveransstart (med {leadTimeInWeeks} veckors ledtid)
        </div>
      ) : '';
    }

    const versions = this.state.versionData?.[0]?.versions;
    const areaSuppliers = area?.suppliers;
    
    let latestExpirationDate;
    if (area?.answersLatestBy && !_.isEmpty(versions)) {
      const latestVersionPerSupplier = {};

      versions.forEach(version => {
        const refVersion = version.refVersion;
        const suppliers = version?.suppliers?.filter(versionSupplier => areaSuppliers.find(areaSupplier => areaSupplier.supplierId._id === versionSupplier.supplierId));
        suppliers.forEach(supplier => {
          if (!latestVersionPerSupplier[supplier.supplierId] || refVersion > latestVersionPerSupplier[supplier.supplierId].refVersion ) {
            latestVersionPerSupplier[supplier.supplierId] = version;
          }
        })
      });

      
      Object.entries(latestVersionPerSupplier).map(([id, version]) => {
        const refVersion = version.refVersion;

        const expiryDate = version.quotationExpiryDate;
        
        const latestExtension = version.updatedSuppliers.find(sup => sup.supplierId === id && sup.version === refVersion)?.endDate;
        const selectedDate = moment(latestExtension).isAfter(expiryDate) ? latestExtension : expiryDate;
        if (!latestExpirationDate || moment(selectedDate).isAfter(latestExpirationDate)) {
          latestExpirationDate = selectedDate;
        }
      })
    }

    const latestExpirationIsAfterplannedDate = area?.answersLatestBy && !_.isEmpty(versions) && moment(latestExpirationDate).isAfter(area.answersLatestBy, 'day');

    return (<div className="app-project-single-purchase">
      <Card title="Allmänt" gap={16}>       
        <div className='firstRowBidCardControls'>
          {this.renderPhaseTransitionDropdown()}
          {this.renderBudgetInput()}
          {this.renderTeamBuilder(areaUsers)}
        </div>
        <div className={styles.datesControls}>
          <span>Tidsplanering</span>
          <div>
            {
              <TableDatePicker
                label='Förfrågan skickas senast'
                fieldClassName={styles.projectAreaDatePicker}
                date={quotationSentBy?.toDate()}
                onFocusStylesEnabled={false}
                isClearable
                onChange={async date => {
                  await ServiceProjects.updateProjectAreaQuotationSentBy(this.props.currentProject.slug, this.props.areaSlug, date);
                }}
              />
            }
            {
              <TableDatePicker
                label='Svar senast'
                warningMessage={latestExpirationIsAfterplannedDate ? <div style={{ maxWidth: '250px', whiteSpace: 'normal' }}>Minst en leverantör har fått en förfrågan med senare anbudsdatum än detta ({moment(latestExpirationDate).locale('sv').format('D MMMM YYYY')}).</div> : ''}
                fieldClassName={styles.projectAreaDatePicker}
                date={answersLatestBy?.toDate()}
                onFocusStylesEnabled={false}
                isClearable
                onChange={async date => {
                  await ServiceProjects.updateProjectAreaAnswersLatestBy(this.props.currentProject.slug, this.props.areaSlug, date);
                }}
              />
            }
            {
              <TableDatePicker
                label='Inköp klart'
                fieldClassName={styles.projectAreaDatePicker}
                date={purchaseCompleted?.toDate()}
                onFocusStylesEnabled={false}
                isClearable
                onChange={async date => {
                  await ServiceProjects.updateAndAutocompleteFields(this.props.currentProject.slug, this.props.areaSlug, 'purchaseCompleted', {
                    purchaseCompleted: date,
                    leadTime: areaLeadTime,
                    productionStartsAt,
                  })
                }}
              />
            }
            {
              <div>
                <TableField
                  label='Ledtid'
                  value={leadTimeInWeeks}
                  onFocusStylesEnabled={false}
                  className={`${styles.projectAreaDatePicker}`}
                  onBlur={async (value) => {
                    await ServiceProjects.updateAndAutocompleteFields(this.props.currentProject.slug, area.areaId.slug, 'leadTime', {
                      purchaseCompleted,
                      leadTime: value,
                      productionStartsAt,
                    });
                  }}
                  isLeadTime
                />
              </div>
            }
            {
              <TableDatePicker
                label='Leveransstart'
                fieldClassName={styles.projectAreaDatePicker}
                date={productionStartsAt?.toDate()}
                onFocusStylesEnabled={false}
                errorMessage={errorMessage}
                isClearable
                onChange={async date => {
                  await ServiceProjects.updateAndAutocompleteFields(this.props.currentProject.slug, this.props.areaSlug, 'productionStartsAt', {
                    purchaseCompleted,
                    leadTime: areaLeadTime,
                    productionStartsAt: date,
                  })
                }}
              />
            }
            {
              <TableDatePicker
                label='Leveransslut'
                fieldClassName={styles.projectAreaDatePicker}
                date={productionEndsAt?.toDate()}
                onFocusStylesEnabled={false}
                isClearable
                onChange={async date => {
                  await ServiceProjects.updateProjectAreaProductionEndsAt(this.props.currentProject.slug, this.props.areaSlug, date);
                }}
              />
            }
          </div>
        </div>

      </Card>
      <div className={styles.row}>
        <Card title="Leverantörer" gap={16}>
          {this.renderSuppliers()}

          {!isReadOnlyUser && (
            <div style={{flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-start"}}>
              <BtButton
                leftIcon={<AddIcon color='var(--white)' />}
                style={{ marginTop: '15px' }}
                class='dark'
                onClick={() => this.props.addSupplier(this.props.currentProject.area.find(area => area.areaId._id == this.props.areaId))}
              >
                Lägg till leverantör
              </BtButton>
            </div>
          )}
        </Card>
        
        {this.renderFirstStepControls()}
      </div>

      {this.renderRequestsAndBids(this.renderSecondStepControls())}
      
      {this.renderThirdStepControls()}


        <Modal
          title='Telefonlogg'
          show={showPhoneLogModal}
          setShow={(value) => this.setState({ showPhoneLogModal: value })}
          buttonInfo={!appState.isReadOnlyAccessRole() && {
            label: 'Lägg till', action: () => {
              if (phoneLogValue && phoneLogValue.label) {
                this.props.onCallDetail(phoneLogValue.label, selectedItem);
                this.setState({ phoneLogValue: null, showPhoneLogModal: false });
              }
            }
          }}
        >
          {phoneLogDetails && phoneLogDetails.length > 0 ? (
            <div style={{ maxHeight: '50vh', overflowY: 'auto', marginBottom: '10px', display: 'grid', gap: '20px' }} ref={(el) => { this.phoneLogDiv = el }}>
              {this.renderPhoneLogs(phoneLogDetails)}
            </div>
          ) : (
            <span style={{ alignSelf: 'center', marginBottom: '16px' }}>Telefonloggen är tom</span>
          )}

          {!appState.isReadOnlyAccessRole() && (
            <TitleWrapper title='Välj meddelande eller skriv valfri text'>
              <Selector
                isCreatable
                value={phoneLogValue}
                onChange={(value) => this.setState({ phoneLogValue: value })}
                options={defaultPhoneLogs}
                formatCreateLabel={(userInput) => `Skriv \"${userInput}\"`}
                placeholder=''
              />
            </TitleWrapper>
          )}
        </Modal>

        <Modal
          title='Välj vinnande leverantör'
          show={showWinnerModal}
          setShow={(value) => this.setState({ showWinnerModal: value })}
          buttonInfo={{
              label: 'Gå vidare till förhandling', 
              action: () => {
                const supplier = suppliers.find(supplier => supplier.supplierId._id == winnerValue.value);
                this.addWinnerClickCallback(supplier.supplierId);
                this.changeStep(3);
                this.setState({ showWinnerModal: false });
              },
              disabled: !winnerValue,
          }}
        >
          <TitleWrapper title='Välj den leverantör du vill gå vidare med'>
            <Selector
              value={winnerValue}
              onChange={(value) => this.setState({ winnerValue: value })}
              options={suppliers
                .filter(supplier => !supplier.supplierId?.isBlacklist)
                .filter(supplier => !["INBOX", "REJECTED"].includes(supplier.supplierId?.prequalification?.decision))
                .map(supplier => ({ label: supplier.supplierId.name, value: supplier.supplierId._id }))
              }
              placeholder=''
            />
          </TitleWrapper>
        </Modal>

        {this.state.showAgreementModal && (
          <Modal
            title='Avtal'
            show={this.state.showAgreementModal}
            setShow={(value) => this.setState({ showAgreementModal: value })}
            hasCloseButton
          >
            <ContractModal 
              deleteContract={this.props.deleteContract}
              currentProject={this.props.currentProject}
              areaSlug={this.props.areaSlug}
              areaId={this.props.areaId}
              isReadOnlyUser={isReadOnlyUser}
              hideModal={() => this.setState({ showAgreementModal: false })}
              selectedContract={this.state.selectedContract}
              refreshCurrentProject={this.props.refreshCurrentProject}
              key={this.state.agreementModalKey}
            />
          </Modal>
        )}
        {selectedItem && <DateExtensionModal
          show={this.state.showDateExtensionModal}
          setShow={(value) => this.setState({ showDateExtensionModal: value })}
          defaultDate={new Date(selectedItem?.date ? selectedItem.date : dateEnd)}
          onSave={this.onSave}
          data={{
            areaName: this.props.label,
            projectName: this.props.currentProject.name,
            version: this.state.currentSelectedVersion
          }}
        />}
    </div>);
  }
}

export default withRouter(AppProjectSinglePurchase);
