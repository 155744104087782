import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import ar from "react-intl/locale-data/ar";
import "moment/locale/ar";
import appState from "./state/AppStateContainer";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import './assets/stencil/app.css';
import "./global/app.css";
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import '@fortawesome/fontawesome-free/css/v4-shims.min.css';
import { createRoot } from 'react-dom/client';

//import registerServiceWorker from './registerServiceWorker';
const domain = window.location.href.split("://")[1].split(/[:/]/)[0];
if (domain.indexOf("accurator.se") != -1) {
  const subdomain = domain.split(".")[0];
  if (subdomain != 'test') {
    Sentry.init({
      environment: subdomain,
      dsn: "https://2e64824280a84d31993fba804afc2e5e@o1151472.ingest.sentry.io/6228181",
      // integrations: [new BrowserTracing()],
    
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // tracesSampleRate: 1.0,
    });
  }
}

moment.locale(appState.state.language);
momentLocalizer();
addLocaleData(en);
addLocaleData(ar);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

//registerServiceWorker();
